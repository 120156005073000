
/**
 * @typedef {Object} TrainingMatch
  * @property {Integer} id
 * @property {Boolean} no_gi
 * @property {Unknown[]} training_match_outcomes
 * @property {Integer} practitioner_profile_id
 * @property {String} practitioner_email
 * @property {String} practitioner_username
 * @property {Integer} training_session_id
 * @property {Unknown} opponent_profile
 * @property {Unknown} opponent_belt
 * @property {String} createdAt
 * @property {String} updatedAt
 */


const TrainingMatches = (requests) => ({
    /**
     * Get all TrainingMatchs
     *
     * @param {Object} query TrainingMatch's query parameters
     * @returns {Promise<TrainingMatchsResponse>}
     */
    all: (query) => requests.get(`/training-matches`, query),

    /**
     * Get one TrainingMatch by ID
     *
     * @param {Number} id TrainingMatch's ID
     * @returns {Promise<TrainingMatchResponse>}
     */
    get: (id) => requests.get(`/training-matches/${id}`),

    /**
     * Create a new TrainingMatch
     *
     * @param {Object} trainingMatch
     * @param {Integer} trainingMatch.id
     * @param {Boolean} trainingMatch.no_gi
     * @param {Unknown[]} trainingMatch.training_match_outcomes
     * @param {Integer} trainingMatch.practitioner_profile_id
     * @param {String} trainingMatch.practitioner_email
     * @param {String} trainingMatch.practitioner_username
     * @param {Integer} trainingMatch.training_session_id
     * @param {Unknown} trainingMatch.opponent_profile
     * @param {Unknown} trainingMatch.opponent_belt
     * @param {String} trainingMatch.createdAt
     * @param {String} trainingMatch.updatedAt
     * @returns {Promise<TrainingMatchResponse>}
     */
    create: (session_id, trainingMatch) => requests.post(`/training-sessions/${session_id}/matches/`, trainingMatch ),

    /**
     * Update one TrainingMatch
     *
     * @param {Number} id TrainingMatch's ID
     * @param {Partial<TrainingMatch>} trainingMatch
     * @returns {Promise<TrainingMatchResponse>}
     */
    update: (match_id, trainingMatch) => requests.put(`/training-matches/${match_id}`, trainingMatch),

    /**
     * Partially update one TrainingMatch
     *
     * @param {Number} id TrainingMatch's ID
     * @param {Partial<TrainingMatch>} trainingMatch
     * @returns {Promise<TrainingMatchResponse>}
     */
    partialUpdate: (match_id, trainingMatch) => requests.patch(`/training-matches/${match_id}`,  trainingMatch ),

    /**
     * Remove one TrainingMatch
     *
     * @param {Number} id TrainingMatch's ID
     * @returns {Promise<{}>}
     */
    del: (match_id) => requests.del(`/training-matches/${match_id}`),

    // Outcomes Endpoints
    Outcomes: {
        
        all: (matchId) => requests.get(`/training-matches/${matchId}/outcomes/`),
        /**
         * List outcomes for a specific match
         * @param {number} sessionId - ID of the training session
         * @param {number} matchId - ID of the match
         * @param {Object} params - Query parameters for pagination
         * @returns {Promise} - Axios response Promise
         */
        list: (matchId, params) => requests.get(`/training-matches/${matchId}/outcomes/`, { params }),
  
        /**
         * Create an outcome for a specific match
         * @param {number} sessionId - ID of the training session
         * @param {number} matchId - ID of the match
         * @param {Object} data - Outcome data
         * @returns {Promise} - Axios response Promise
         */
        create: ( matchId, data) => requests.post(`/training-matches/${matchId}/outcomes/`, data),
  
        /**
         * Retrieve a specific outcome by ID
         * @param {number} sessionId - ID of the training session
         * @param {number} matchId - ID of the match
         * @param {number} outcomeId - ID of the outcome
         * @returns {Promise} - Axios response Promise
         */
        retrieve: (matchId, outcomeId) => requests.get(`/training-matches/${matchId}/outcomes/${outcomeId}`),
  
        /**
         * Update an outcome by ID
         * @param {number} sessionId - ID of the training session
         * @param {number} matchId - ID of the match
         * @param {number} outcomeId - ID of the outcome
         * @param {Object} data - Updated outcome data
         * @returns {Promise} - Axios response Promise
         */
        update: (matchId, outcomeId, data) => requests.put(`/training-matches/${matchId}/outcomes/${outcomeId}`, data),
  
        /**
         * Partially update an outcome by ID
         * @param {number} sessionId - ID of the training session
         * @param {number} matchId - ID of the match
         * @param {number} outcomeId - ID of the outcome
         * @param {Object} data - Partially updated outcome data
         * @returns {Promise} - Axios response Promise
         */
        partialUpdate: (matchId, outcomeId, data) => requests.patch(`/training-matches/${matchId}/outcomes/${outcomeId}`, data),
  
        /**
         * Delete an outcome by ID
         * @param {number} sessionId - ID of the training session
         * @param {number} matchId - ID of the match
         * @param {number} outcomeId - ID of the outcome
         * @returns {Promise} - Axios response Promise
         */
        destroy: (matchId, outcomeId) => requests.delete(`/training-matches/${matchId}/outcomes/${outcomeId}`),
      },
});

export default TrainingMatches;
