// src/index.js
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './components/App';
import store from './app/store';
import Auth0ProviderWithHistory from './features/auth/Auth0ProviderWithHistory';
// import { FireAuthProvider } from './features/auth/FireAuthContext';


const LOGGLY_CONFIG = {
  token: process.env.REACT_APP_LOGGLY_TOKEN,
  subdomain: process.env.REACT_APP_LOGGLY_SUBDOMAIN,
  tags: [process.env.REACT_APP_LOGGER_APP_TAG],
  json: true,
};

// Create the root and render the application
createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <Router>
    <Auth0ProviderWithHistory>
      <App />
    </Auth0ProviderWithHistory>            
    </Router>
  </Provider>
);

// Expose the Redux store in Cypress for testing
/* istanbul ignore else */
if (window.Cypress) {
  window.store = store;
}