// /src/api/training-sessions.js

/**
 * Training Sessions API
 * @param {Object} requests - The request object for making HTTP calls.
 */
const TrainingSessions = (requests) => ({
    /**
     * List training sessions
     * @param {Object} params - Query parameters for filtering and pagination
     * @returns {Promise} - Axios response Promise
     */
    all: (query) => requests.get('/training-sessions', { query }),

    list: (params) => requests.get('/training-sessions', { params }),
  
    /**
     * Create a new training session
     * @param {Object} data - Training session data
     * @returns {Promise} - Axios response Promise
     */
    create: (data) => requests.post('/training-sessions/', data),
  
    /**
     * Retrieve a specific training session by ID
     * @param {number} sessionId - ID of the training session
     * @returns {Promise} - Axios response Promise
     */
    get: (sessionId) => requests.get(`/training-sessions/${sessionId}`),
  
    /**
     * Update a training session by ID
     * @param {number} sessionId - ID of the training session
     * @param {Object} data - Updated training session data
     * @returns {Promise} - Axios response Promise
     */
    update: (sessionId, trainingSessionData) => requests.put(`/training-sessions/${sessionId}`, trainingSessionData),    
    
    /**
     * Delete a training session by ID
     * @param {number} sessionId - ID of the training session
     * @returns {Promise} - Axios response Promise
     */
    del: (slug) => requests.del(`/training-sessions/${slug}`),    
  
    // Matches Endpoints
    Matches: {
      /**
       * List matches for a specific training session
       * @param {number} sessionId - ID of the training session
       * @param {Object} params - Query parameters for pagination
       * @returns {Promise} - Axios response Promise
       */
      all: (sessionId) => requests.get(`/training-sessions/${sessionId}/matches/`),


      list: (sessionId, params) => requests.get(`/training-sessions/${sessionId}/matches/`, { params }),
  
      /**
       * Create a match for a specific training session
       * @param {number} sessionId - ID of the training session
       * @param {Object} data - Match data
       * @returns {Promise} - Axios response Promise
       */
      create: (sessionId, data) => requests.post(`/training-sessions/${sessionId}/matches/`, data),
  
      /**
       * Retrieve a specific match by ID
       * @param {number} sessionId - ID of the training session
       * @param {number} matchId - ID of the match
       * @returns {Promise} - Axios response Promise
       */
      retrieve: (sessionId, matchId) => requests.get(`/training-sessions/${sessionId}/matches/${matchId}`),
  
      /**
       * Update a match by ID
       * @param {number} sessionId - ID of the training session
       * @param {number} matchId - ID of the match
       * @param {Object} data - Updated match data
       * @returns {Promise} - Axios response Promise
       */
      update: (sessionId, matchId, data) => requests.put(`/training-sessions/${sessionId}/matches/${matchId}`, data),
  
      /**
       * Partially update a match by ID
       * @param {number} sessionId - ID of the training session
       * @param {number} matchId - ID of the match
       * @param {Object} data - Partially updated match data
       * @returns {Promise} - Axios response Promise
       */
      partialUpdate: (sessionId, matchId, data) => requests.patch(`/training-sessions/${sessionId}/matches/${matchId}`, data),
  
      /**
       * Delete a match by ID
       * @param {number} sessionId - ID of the training session
       * @param {number} matchId - ID of the match
       * @returns {Promise} - Axios response Promise
       */
      destroy: (sessionId, matchId) => requests.delete(`/training-sessions/${sessionId}/matches/${matchId}`),
  
      // Outcomes Endpoints
      Outcomes: {
        /**
         * List outcomes for a specific match
         * @param {number} sessionId - ID of the training session
         * @param {number} matchId - ID of the match
         * @param {Object} params - Query parameters for pagination
         * @returns {Promise} - Axios response Promise
         */
        list: (sessionId, matchId, params) => requests.get(`/training-sessions/${sessionId}/matches/${matchId}/outcomes/`, { params }),
  
        /**
         * Create an outcome for a specific match
         * @param {number} sessionId - ID of the training session
         * @param {number} matchId - ID of the match
         * @param {Object} data - Outcome data
         * @returns {Promise} - Axios response Promise
         */
        create: (sessionId, matchId, data) => requests.post(`/training-sessions/${sessionId}/matches/${matchId}/outcomes/`, data),
  
        /**
         * Retrieve a specific outcome by ID
         * @param {number} sessionId - ID of the training session
         * @param {number} matchId - ID of the match
         * @param {number} outcomeId - ID of the outcome
         * @returns {Promise} - Axios response Promise
         */
        retrieve: (sessionId, matchId, outcomeId) => requests.get(`/training-sessions/${sessionId}/matches/${matchId}/outcomes/${outcomeId}`),
  
        /**
         * Update an outcome by ID
         * @param {number} sessionId - ID of the training session
         * @param {number} matchId - ID of the match
         * @param {number} outcomeId - ID of the outcome
         * @param {Object} data - Updated outcome data
         * @returns {Promise} - Axios response Promise
         */
        update: (sessionId, matchId, outcomeId, data) => requests.put(`/training-sessions/${sessionId}/matches/${matchId}/outcomes/${outcomeId}`, data),
  
        /**
         * Partially update an outcome by ID
         * @param {number} sessionId - ID of the training session
         * @param {number} matchId - ID of the match
         * @param {number} outcomeId - ID of the outcome
         * @param {Object} data - Partially updated outcome data
         * @returns {Promise} - Axios response Promise
         */
        partialUpdate: (sessionId, matchId, outcomeId, data) => requests.patch(`/training-sessions/${sessionId}/matches/${matchId}/outcomes/${outcomeId}`, data),
  
        /**
         * Delete an outcome by ID
         * @param {number} sessionId - ID of the training session
         * @param {number} matchId - ID of the match
         * @param {number} outcomeId - ID of the outcome
         * @returns {Promise} - Axios response Promise
         */
        destroy: (sessionId, matchId, outcomeId) => requests.delete(`/training-sessions/${sessionId}/matches/${matchId}/outcomes/${outcomeId}`),
      },
    },
  });
  
  export default TrainingSessions;