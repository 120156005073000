// src/api/journey.js

/**
 * @typedef {Object} Goal
 * @property {Integer} id
 * @property {String} name
 * @property {String} description
 * @property {String} status
 * @property {Integer} belt_level
 * @property {Date} created_at
 * @property {Date} updated_at
 */

/**
 * @typedef {Object} GoalType
 * @property {Integer} id
 * @property {String} name
 */

/**
 * @typedef {Object} Journey
 * @property {Integer} id
 * @property {Integer} user_id
 * @property {Integer} goal_id
 * @property {Date} start_date
 * @property {Date} end_date
 * @property {String} status
 */

const Journey = (requests) => ({
    /**
     * Get all Goal Types
     *
     * @param {Object} query GoalType's query parameters
     * @returns {Promise<GoalType[]>}
     */
    getGoalTypes: (query) => requests.get(`/goal-types`, query),

    /**
     * Get one Goal Type by ID
     *
     * @param {Number} id GoalType's ID
     * @returns {Promise<GoalType>}
     */
    getGoalType: (id) => requests.get(`/goal-types/${id}`),

    /**
     * Create a new Goal Type
     *
     * @param {Object} goalType
     * @param {String} goalType.name
     * @returns {Promise<GoalType>}
     */
    createGoalType: (goalType) => requests.post(`/goal-types`, { goalType }),

    /**
     * Update one Goal Type
     *
     * @param {Number} id GoalType's ID
     * @param {Partial<GoalType>} goalType
     * @returns {Promise<GoalType>}
     */
    updateGoalType: (id, goalType) => requests.put(`/goal-types/${id}`, { goalType }),

    /**
     * Remove one Goal Type
     *
     * @param {Number} id GoalType's ID
     * @returns {Promise<{}>}
     */
    delGoalType: (id) => requests.del(`/goal-types/${id}`),

    /**
     * Get all Goals
     *
     * @param {Object} query Goal's query parameters
     * @returns {Promise<Goal[]>}
     */
    allGoals: (query) => requests.get(`/goals`, query),

    /**
     * Get one Goal by ID
     *
     * @param {Number} id Goal's ID
     * @returns {Promise<Goal>}
     */
    getGoal: (id) => requests.get(`/goals/${id}`),

    /**
     * Create a new Goal
     *
     * @param {Object} goal
     * @param {String} goal.name
     * @param {String} goal.description
     * @param {String} goal.status
     * @param {Integer} goal.belt_level
     * @returns {Promise<Goal>}
     */
    createGoal: (goal) => requests.post(`/goals`,  goal ),

    /**
     * Update one Goal
     *
     * @param {Number} id Goal's ID
     * @param {Partial<Goal>} goal
     * @returns {Promise<Goal>}
     */
    updateGoal: (id, goal) => requests.put(`/goals/${id}`,  goal ),

    /**
     * Partially update one Goal
     *
     * @param {Number} id Goal's ID
     * @param {Partial<Goal>} goal
     * @returns {Promise<Goal>}
     */
    partialUpdateGoal: (id, goal) => requests.patch(`/goals/${id}`,  goal ),

    /**
     * Remove one Goal
     *
     * @param {Number} id Goal's ID
     * @returns {Promise<{}>}
     */
    delGoal: (id) => requests.del(`/goals/${id}`),

    /**
     * Get all Journeys
     *
     * @param {Object} query Journey's query parameters
     * @returns {Promise<Journey[]>}
     */
    allJourneys: (query) => requests.get(`/journeys`, query),

    /**
     * Get one Journey by ID
     *
     * @param {Number} id Journey's ID
     * @returns {Promise<Journey>}
     */
    getJourney: (id) => requests.get(`/journeys/${id}`),

    /**
     * Create a new Journey
     *
     * @param {Object} journey
     * @param {Integer} journey.user_id
     * @param {Integer} journey.goal_id
     * @param {Date} journey.start_date
     * @param {Date} journey.end_date
     * @param {String} journey.status
     * @returns {Promise<Journey>}
     */
    createJourney: (journey) => requests.post(`/journeys/`, journey ),

    /**
     * Update one Journey
     *
     * @param {Number} id Journey's ID
     * @param {Partial<Journey>} journey
     * @returns {Promise<Journey>}
     */
    updateJourney: (id, journey) => requests.put(`/journeys/${id}`, journey ),

    /**
     * Partially update one Journey
     *
     * @param {Number} id Journey's ID
     * @param {Partial<Journey>} journey
     * @returns {Promise<Journey>}
     */
    partialUpdateJourney: (id, journey) => requests.patch(`/journeys/${id}`,  journey ),

    /**
     * Remove one Journey
     *
     * @param {Number} id Journey's ID
     * @returns {Promise<{}>}
     */
    delJourney: (id) => requests.del(`/journeys/${id}`),

    /**
     * Get all Goals in Journey Order
     *
     * @param {Object} query Journey Goal's query parameters
     * @returns {Promise<Goal[]>}
     */
    allJourneyGoals: (query) => requests.get(`/goals-order`, query),

    /**
     * Get one Journey Goal by ID
     *
     * @param {Number} id Journey Goal's ID
     * @returns {Promise<Goal>}
     */
    getJourneyGoal: (id) => requests.get(`/goals-order/${id}`),

    /**
     * Create a new Journey Goal
     *
     * @param {Object} journeyGoal
     * @param {Integer} journeyGoal.goal_id
     * @param {Integer} journeyGoal.user_id
     * @param {Date} journeyGoal.start_date
     * @param {Date} journeyGoal.end_date
     * @param {String} journeyGoal.status
     * @returns {Promise<Goal>}
     */
    createJourneyGoal: (journeyGoal) => requests.post(`/goals-order`, journeyGoal ),

    /**
     * Update one Journey Goal
     *
     * @param {Number} id Journey Goal's ID
     * @param {Partial<Goal>} journeyGoal
     * @returns {Promise<Goal>}
     */
    updateJourneyGoal: (id, journeyGoal) => requests.put(`/goals-order/${id}`,  journeyGoal ),

    /**
     * Remove one Journey Goal
     *
     * @param {Number} id Journey Goal's ID
     * @returns {Promise<{}>}
     */
    delJourneyGoal: (id) => requests.del(`/goals-order/${id}`)
});

export default Journey;