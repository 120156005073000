
/**
 * @typedef {Object} Notification
  * @property {Integer} id
 * @property {Integer} parent_belt
 * @property {String} name
 */

const Notifications = (requests) => ({
    /**
     * Get all Notifications
     *
     * @param {Object} query Notification's query parameters
     * @returns {Promise<NotificationsResponse>}
     */
    all: (query) => requests.get(`/notifications`, query),

    /**
     * Get one Notification by ID
     *
     * @param {Number} id Notification's ID
     * @returns {Promise<NotificationResponse>}
     */
    get: (id) => requests.get(`/notifications/${id}`),

    /**
     * Create a new Notification
     *
     * @param {Object} notification
      * @param {Integer} notification.id
     * @param {Integer} notification.parent_belt
     * @param {String} notification.name
     * @returns {Promise<NotificationResponse>}
     */
    create: (notification) => requests.post(`/notifications`, { notification }),

    /**
     * Update one Notification
     *
     * @param {Number} id Notification's ID
     * @param {Partial<Notification>} notification
     * @returns {Promise<NotificationResponse>}
     */
    update: (id, notification) => requests.put(`/notifications/${id}`, { notification }),

    /**
     * Partially update one Notification
     *
     * @param {Number} id Notification's ID
     * @param {Partial<Notification>} notification
     * @returns {Promise<NotificationResponse>}
     */
    partialUpdate: (id, notification) => requests.patch(`/notifications/${id}`, { notification }),

    /**
     * Remove one Notification
     *
     * @param {Number} id Notification's ID
     * @returns {Promise<{}>}
     */
    del: (id) => requests.del(`/notifications/${id}`),
});

export default Notifications;
