import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import agent from '../api';
import logger from '../common/logger';

const fileName = 'Reducer: src/reducers/journey.js';

const initialState = {
  journeys: [],
  journey: {},
  goals: [],
  goalTypes: [],
  loading: false,
  error: undefined,
};

// Thunks for Journey API calls

export const getAllJourneys = createAsyncThunk(
  'journey/getAllJourneys',
  async (query, thunkApi) => {
    try {
      logger.logDebug(fileName, 'Fetching all journeys', { query });
      const response = await agent.Journey.allJourneys(query);
      logger.logDebug(fileName, 'Fetched all journeys successfully', { response });
      return response;
    } catch (error) {
      logger.logError(fileName, 'Error fetching journeys', { error });
      throw error;
    }
  }
);

export const getJourney = createAsyncThunk(
  'journey/getJourney',
  async (id, thunkApi) => {
    try {
      logger.logDebug(fileName, 'Fetching journey by ID', { id });
      const response = await agent.Journey.getJourney(id);
      logger.logDebug(fileName, 'Fetched journey successfully', { response });
      return response;
    } catch (error) {
      logger.logError(fileName, 'Error fetching journey', { error });
      throw error;
    }
  }
);


// Create a Journey
export const createJourney = createAsyncThunk(
  'journey/createJourney',
  async ({ journeyData }, { rejectWithValue }) => {
    try {
      logger.logDebug(fileName, 'Creating journey', { journeyData });
      const response = await agent.Journey.createJourney(journeyData); // Assuming agent.Goal.create is the API method
      logger.logDebug(fileName, 'Goal created successfully', { response });
      return response.data;
    } catch (error) {
      logger.logError(fileName, 'Error creating goal', { error });
      return rejectWithValue(error.response?.data || error);
    }
  }
);

// New Thunks for Goals and Goal Types

export const getGoals = createAsyncThunk(
  'journey/getGoals',
  async (journeyId, thunkApi) => {
    try {
      logger.logDebug(fileName, 'Fetching goals for journey', { journeyId });
      const response = await agent.Goal.getGoals(journeyId); // Assuming agent.Goal.getGoals is the API method
      logger.logDebug(fileName, 'Fetched goals successfully', { response });
      return response;
    } catch (error) {
      logger.logError(fileName, 'Error fetching goals', { error });
      throw error;
    }
  }
);

export const getGoalTypes = createAsyncThunk(
  'journey/getGoalTypes',
  async (_, thunkApi) => {
    try {
      logger.logDebug(fileName, 'Fetching goal types');
      const response = await agent.GoalType.all(); // Assuming agent.GoalType.all is the API method
      logger.logDebug(fileName, 'Fetched goal types successfully', { response });
      return response;
    } catch (error) {
      logger.logError(fileName, 'Error fetching goal types', { error });
      throw error;
    }
  }
);

// Create a goal

export const createGoal = createAsyncThunk(
  'journey/createGoal',
  async ({ journeyId, goalData }, { rejectWithValue }) => {
    try {
      logger.logDebug(fileName, 'Creating goal for journey', { journeyId, goalData });
      const response = await agent.Goal.create(journeyId, goalData); // Assuming agent.Goal.create is the API method
      logger.logDebug(fileName, 'Goal created successfully', { response });
      return response.data;
    } catch (error) {
      logger.logError(fileName, 'Error creating goal', { error });
      return rejectWithValue(error.response?.data || error);
    }
  }
);

// Update a goal

export const updateGoal = createAsyncThunk(
  'journey/updateGoal',
  async ({ goalId, goalData }, { rejectWithValue }) => {
    try {
      logger.logDebug(fileName, 'Updating goal', { goalId, goalData });
      const response = await agent.Goal.update(goalId, goalData); // Assuming agent.Goal.update is the API method
      logger.logDebug(fileName, 'Goal updated successfully', { response });
      return response.data;
    } catch (error) {
      logger.logError(fileName, 'Error updating goal', { error });
      return rejectWithValue(error.response?.data || error);
    }
  }
);

// Delete a goal

export const deleteGoal = createAsyncThunk(
  'journey/deleteGoal',
  async (goalId, { rejectWithValue }) => {
    try {
      logger.logDebug(fileName, 'Deleting goal', { goalId });
      const response = await agent.Goal.delete(goalId); // Assuming agent.Goal.delete is the API method
      logger.logDebug(fileName, 'Goal deleted successfully', { response });
      return goalId; // Returning goalId to handle deletion in the state
    } catch (error) {
      logger.logError(fileName, 'Error deleting goal', { error });
      return rejectWithValue(error.response?.data || error);
    }
  }
);

// Creating, updating, and deleting goal types

export const createGoalType = createAsyncThunk(
  'journey/createGoalType',
  async (goalTypeData, { rejectWithValue }) => {
    try {
      logger.logDebug(fileName, 'Creating goal type', { goalTypeData });
      const response = await agent.GoalType.create(goalTypeData); // Assuming agent.GoalType.create is the API method
      logger.logDebug(fileName, 'Goal type created successfully', { response });
      return response.data;
    } catch (error) {
      logger.logError(fileName, 'Error creating goal type', { error });
      return rejectWithValue(error.response?.data || error);
    }
  }
);

// Update a goal type

export const updateGoalType = createAsyncThunk(
  'journey/updateGoalType',
  async ({ goalTypeId, goalTypeData }, { rejectWithValue }) => {
    try {
      logger.logDebug(fileName, 'Updating goal type', { goalTypeId, goalTypeData });
      const response = await agent.GoalType.update(goalTypeId, goalTypeData); // Assuming agent.GoalType.update is the API method
      logger.logDebug(fileName, 'Goal type updated successfully', { response });
      return response.data;
    } catch (error) {
      logger.logError(fileName, 'Error updating goal type', { error });
      return rejectWithValue(error.response?.data || error);
    }
  }
);

// Delete a goal type

export const deleteGoalType = createAsyncThunk(
  'journey/deleteGoalType',
  async (goalTypeId, { rejectWithValue }) => {
    try {
      logger.logDebug(fileName, 'Deleting goal type', { goalTypeId });
      const response = await agent.GoalType.delete(goalTypeId); // Assuming agent.GoalType.delete is the API method
      logger.logDebug(fileName, 'Goal type deleted successfully', { response });
      return goalTypeId; // Returning goalTypeId to handle deletion in the state
    } catch (error) {
      logger.logError(fileName, 'Error deleting goal type', { error });
      return rejectWithValue(error.response?.data || error);
    }
  }
);

const journeySlice = createSlice({
  name: 'journey',
  initialState,
  reducers: {
    journeyPageUnloaded: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllJourneys.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllJourneys.fulfilled, (state, action) => {
        state.journeys = action.payload.results;
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllJourneys.rejected, (state, action) => {
        state.journeys = [];
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getJourney.fulfilled, (state, action) => {
        state.journey = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getJourney.rejected, (state, action) => {
        state.journey = {};
        state.loading = false;
        state.error = action.error.message;
      })
      // Goals and Goal Types cases
      .addCase(getGoals.fulfilled, (state, action) => {
        state.goals = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getGoals.rejected, (state, action) => {
        state.goals = [];
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getGoalTypes.fulfilled, (state, action) => {
        state.goalTypes = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getGoalTypes.rejected, (state, action) => {
        state.goalTypes = [];
        state.loading = false;
        state.error = action.error.message;
      })
      // Create, update, and delete goal cases
      .addCase(createGoal.fulfilled, (state, action) => {
        state.goals.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createGoal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateGoal.fulfilled, (state, action) => {
        const index = state.goals.findIndex(goal => goal.id === action.payload.id);
        if (index !== -1) {
          state.goals[index] = action.payload;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updateGoal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteGoal.fulfilled, (state, action) => {
        state.goals = state.goals.filter(goal => goal.id !== action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteGoal.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Create, update, and delete goal type cases
      .addCase(createGoalType.fulfilled, (state, action) => {
        state.goalTypes.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createGoalType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateGoalType.fulfilled, (state, action) => {
        const index = state.goalTypes.findIndex(goalType => goalType.id === action.payload.id);
        if (index !== -1) {
          state.goalTypes[index] = action.payload;
        }
        state.loading = false;
        state.error = null;
      })
      .addCase(updateGoalType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteGoalType.fulfilled, (state, action) => {
        state.goalTypes = state.goalTypes.filter(goalType => goalType.id !== action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteGoalType.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { journeyPageUnloaded } = journeySlice.actions;

export default journeySlice.reducer;