
const Auth = (requests) => ({
  /**
   * Get current user
   *
   * @returns {Promise<UserAuth>}
   */
  current: () => requests.get('/user'),
  /**
   * Login with email and password
   *
   * @param {String} email
   * @param {String} password
   * @returns {Promise<UserAuth>}
   */
  login: (email, password) =>
    requests.post('/auth/login', { user: { email, password } }),

  auth0Tokenlogin: (auth0Token) =>
    requests.post('/auth/auth0/login', { token: {auth0Token} }),

  firebaseTokenlogin: (fireToken) =>
    requests.post('/firebase/login', { token: {fireToken} }),

  /**
   * Register with username, email and password
   *
   * @param {String} username
   * @param {String} email
   * @param {String} password
   * @returns {Promise<UserAuth>}
   */
  register: (username, email, password) =>
    requests.post('/auth/register', { user: { username, email, password } }),

    /**
   * Request Invite
   *   
   * @param {String} email   
   * @returns {Promise<UserAuth>}
   */
    request_invite: (email) =>
      requests.post('/auth/request-invite', { email: email }),
  /**
   * Update user
   *
   * @param {Object}  user
   * @param {String} [user.email]
   * @param {String} [user.username]
   * @param {String} [user.bio]
   * @param {String} [user.image]
   * @param {String} [user.password]
   * @returns {Promise<UserAuth>}
   */
  save: (user) => requests.put('/user', { user }),
});
  
export default Auth;