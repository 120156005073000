// import React, { memo, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// import Banner from './Banner';
// import MainView from './MainView';
// import { selectIsAuthenticated } from '../../features/auth/authSlice';

// /**
//  * Home screen component
//  *
//  * @example
//  * <Home />
//  */
// function Home() {

//   return (
//     <div className="home-page">
//       <Banner />
//       <div className="container page">
//         <div className="row">
//           <MainView />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default memo(Home);


import React, { memo, useEffect } from 'react';
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <Container className="text-center py-5">
      <h1 className="mb-4">Welcome to Jitz Journey!</h1>
      <p className="lead mb-5">
        Track your jiu-jitsu progress, review detailed reports, and achieve your
        goals. Start your journey today!
      </p>
      <Row className="g-4 mb-4">
        <Col md={6} sm={12}>
          <Link to="/training-sessions/editor" className="text-decoration-none">
            <Card className="h-100 shadow card-hover">
              <Card.Body>
                <Card.Title className="card-title">Record Training</Card.Title>
                <Card.Text className="card-text">
                  Just finished training and want to record your Session?
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={6} sm={12}>
          <Link to="/journey" className="text-decoration-none">
            <Card className="h-100 shadow card-hover">
              <Card.Body>
                <Card.Title className="card-title">Explore Your Journey</Card.Title>
                <Card.Text className="card-text">
                  Reflect on your milestones and plan your path toward mastery in
                  jiu-jitsu.
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>
      <Row className="g-4 mb-4">
        <Col md={6} sm={12}>
          <Link to="/reports" className="text-decoration-none">
            <Card className="h-100 shadow card-hover">
              <Card.Body>
                <Card.Title className="card-title">View Your Reports</Card.Title>
                <Card.Text className="card-text">
                  Access detailed insights and analytics about your training
                  sessions and performance.
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        <Col md={6} sm={12}>
          <Link to="/TechniqueLibrary" className="text-decoration-none">
            <Card className="h-100 shadow card-hover">
              <Card.Body>
                <Card.Title className="card-title">Learn Techniques</Card.Title>
                <Card.Text className="card-text">
                  Explore a library of videos we endorse to help you on your journey.
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default memo(Home);