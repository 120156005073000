import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownToggle, Navbar, NavbarToggler, NavbarBrand, Collapse, DropdownMenu, DropdownItem, Nav, NavItem, NavLink } from 'reactstrap';
import { useNavigate } from 'react-router';
import { selectIsAuthenticated, selectUser, logout as logoutRedux} from '../features/auth/authSlice';

import { useAuth0 } from '@auth0/auth0-react';


/**
 * Navbar when there isn't a logged user
 *
 * @example
 * <LoggedOutNavbar />
 */
function LoggedOutNavbar() {

  return (
    <Nav className="ms-auto" navbar>
      <NavItem>
        <NavLink tag={Link} to="/auth0login" className="nav-link">
          Auth0 Login
        </NavLink>
      </NavItem>
    </Nav>
  );
}

/**
 * Navbar when there is a logged user
 *
 * @example
 * <LoggedInNavbar />
 */
function LoggedInNavbar() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUser);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavBar = () => setIsOpen(!isOpen);
  const { logout: auth0Logout } = useAuth0();
  const navigate = useNavigate();  
  // const notifications = useSelector();

  const logoutUser = () => {        
    auth0Logout({ returnTo: window.location.origin });  
    dispatch(logoutRedux());    
    navigate('/');
  };

  return (
    <>
      <NavbarToggler onClick={toggleNavBar} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ms-auto" navbar>
          <NavItem>
            <NavLink tag={Link} to="/" className="nav-link">
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/training-sessions/editor" className="nav-link">
              Record Training
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/journey" className="nav-link">
              My Journey
            </NavLink>
          </NavItem>
          <Dropdown nav isOpen={dropdownOpen} toggle={toggleDropDown}>
            <DropdownToggle nav caret>
              <img
                src={
                  currentUser?.image ||
                  'https://static.productionready.io/images/smiley-cyrus.jpg'
                }
                className="user-pic"
                alt={currentUser?.username}
              />
              {currentUser?.username}
            </DropdownToggle>
            <DropdownMenu end>              
              <DropdownItem tag={Link} to="/settings">Profile</DropdownItem>
              <DropdownItem tag={Link} to="/training-sessions">Trainings</DropdownItem>
              <DropdownItem tag={Link} to="/reports">Reports</DropdownItem>
              <DropdownItem onClick={logoutUser}>Sign out</DropdownItem> 
            </DropdownMenu>
          </Dropdown>
        </Nav>
      </Collapse>
    </>
  );
}

/**
 * App header
 *
 * @example
 * <Header />
 */
function Header() {
  // const { loginWithRedirect, logout, isAuthenticated, user, getAccessTokenSilently, isLoading } = useAuth0();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const appName = useSelector((state) => state.common.appName);
  
  return (
    <Navbar color="light" light expand="lg">      
     <NavbarBrand href="/">{appName.toUpperCase()}</NavbarBrand>
        {/* <Link to="/" className="navbar-brand">
          
        </Link> */}
        {isAuthenticated ? <LoggedInNavbar  /> : <LoggedOutNavbar />}      
    </Navbar>


  );
}

export default memo(Header);