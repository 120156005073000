// src/components/App.jsx
import React, { lazy, Suspense, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Home from "../components/Home";
import { appLoad, clearRedirect } from "../reducers/common";
import Header from "./Header";
import Notifications from "./Notifications";
import logger from "../common/logger";
import { Private } from "./RouteProtection";
import Footer from "./Footer";

const ReifersOpponentReport = lazy(() =>
  import(
    /* webpackChunkName: "ReifersOpponentReport", webpackPrefetch: true  */ "./Reports/Reifers/Opponent"
  )
);

const Journey = lazy(() =>
import(
    /* webpackChunkName: "ReifersOpponentReport", webpackPrefetch: true  */ "./Journey/"
  )
);

const ReifersReport = lazy(() =>
  import(
    /* webpackChunkName: "ReifersReport", webpackPrefetch: true  */ "./Reports/Reifers/"
  )
);
const Reports = lazy(() =>
  import(/* webpackChunkName: "Reports", webpackPrefetch: true  */ "./Reports/")
);
const TrainingSessions = lazy(() =>
  import(
    /* webpackChunkName: "TrainingSessions", webpackPrefetch: true  */ "./TrainingSessions/"
  )
);
const TrainingMatches = lazy(() =>
  import(
    /* webpackChunkName: "TrainingMatches", webpackPrefetch: true  */ "./TrainingSessions/Matches/"
  )
);
const TrainingSessionEditor = lazy(() =>
  import(
    /* webpackChunkName: "TrainingSessionEditor", webpackPrefetch: true  */ "./TrainingSessions/Editor"
  )
);
const AuthScreen = lazy(() =>
  import(
    /* webpackChunkName: "AuthScreen", webpackPrefetch: true  */ "../features/auth/AuthScreen"
  )
);
// const Profile = lazy(() =>
//   import(
//     /* webpackChunkName: "Profile", webpackPrefetch: true  */ "../components/Profile"
//   )
// );
const SettingsScreen = lazy(() =>
  import(
    /* webpackChunkName: "SettingsScreen", webpackPrefetch: true  */ "../features/auth/SettingsScreen"
  )
);
const Auth0AuthScreen = lazy(() =>
  import(
    /* webpackChunkName: "SettingsScreen", webpackPrefetch: true  */ "../features/auth/Auth0AuthScreen"
  )
);



function App() {
  const dispatch = useDispatch();
  const redirectTo = useSelector((state) => state.common.redirectTo);
  const appLoaded = useSelector((state) => state.common.appLoaded);

  useEffect(() => {
    if (redirectTo) {
      logger.logDebug("App", "Redirecting to: " + redirectTo);
      dispatch(clearRedirect());
    }
  }, [redirectTo, dispatch]);

  useEffect(() => {
    const token = window.localStorage.getItem("jitzjourney-jwt");
    logger.logDebug("App", "App loading with token : " + token);
    dispatch(appLoad(token));
  }, [dispatch]);

  if (appLoaded) {
    logger.logDebug("App", "App has loaded, rendering routes...");
    return (
      <>
        <Header />
        <Notifications />
        <Suspense fallback={<p>Loading...</p>}>
          <Routes>
            <Route exact path="/" element={<Home />} />            
            <Route path="/register" element={<AuthScreen isRegisterScreen />} />
            <Route path="/auth0login" element={<Auth0AuthScreen />} />
            <Route path="/reports" element={<Private Component={Reports} />} />
            <Route path="/journey" element={<Private Component={Journey} />} />
            <Route path="/reports/reifers" element={<ReifersReport />} />
            <Route
              path="/reports/reifers/opponent/:profile_id"
              element={<ReifersOpponentReport />}  /> 
            <Route
              path="/settings"
              element={<Private Component={SettingsScreen} />}
            />
            <Route
              path="/training-sessions"
              element={<Private Component={TrainingSessions} />}
            />
            <Route
              path="/training-sessions/:training_session_id"
              element={<Private Component={TrainingMatches} />}
            />
            <Route
              path="/training-sessions/editor"
              element={<Private Component={TrainingSessionEditor} />}
            />
            <Route
              path="/training-sessions/:training_session_id/editor"
              element={<Private Component={TrainingSessionEditor} />}
            />
            <Route
              path="/training-sessions/:training_session_id/matches"
              element={<Private Component={TrainingMatches} />}
            />
            {/* <Route
              path="/@:username/favorites"
              element={<Private Component={() => <Profile isFavoritePage />} />}
            />           */}
          </Routes>
        </Suspense>
        <Footer></Footer>
      </>
    );
  } else {
    logger.logDebug("App", "App not loaded, still loading...");
    return (
      <>        
        <div className="loading-container">
           <img src="/loading.gif" alt="Loading..." className="loading-image" />
        </div>
      </>
    );
  }
};


export default memo(App);
