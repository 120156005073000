import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import agent from '../api';
import logger from '../common/logger';

const fileName = 'Reducer: src/reducers/sharedData.js';

const initialState = {
  notification: {},
  notifications: [],
  shared_data: {},
  shared_data_list: [],
  loading: false,
  error: undefined,
};


export const getNotification = createAsyncThunk(
  'notification/getNotification',
  agent.Notifications.get
);

export const getSharedData = createAsyncThunk(
  'shared-data/getSharedData',
  agent.SharedData.get
);


export const findSharedData = createAsyncThunk(
  'shared-data/findSharedData',
  async ({ search = '' } = {}, thunkApi) => {
    const searchQuery = search;
    try {
      logger.logDebug(fileName, 'Fetching Shared Data', { searchQuery });
      const response = await agent.SharedData.all({ search });
      logger.logDebug(fileName, 'Fetched Shared Data successfully', { response });
      return response;
    } catch (error) {
      logger.logError(fileName, 'Error fetching Shared Data', { error });
      throw error;
    }
  }
);


export const findNotifications = createAsyncThunk(
  'notification/findNotifications',
  async ({ search = '' } = {}, thunkApi) => {
    const searchQuery = search;
    try {
      logger.logDebug(fileName, 'Fetching Notifications', { searchQuery });
      const response = await agent.Notifications.all({ search });
      logger.logDebug(fileName, 'Fetched notifications successfully', { response });
      return response;
    } catch (error) {
      logger.logError(fileName, 'Error fetching notifications', { error });
      throw error;
    }
  }
);

export const updateNotification = createAsyncThunk(
  'notification/updateNotification',
  async ({ notificationId, notificationData }, { rejectWithValue }) => {
    try {
      const response = await agent.Notifications.partialUpdate(notificationId, notificationData);
      return response.data;
    } catch (error) {
      logger.logError(fileName, `updateNotification error : ${error}`);
      return rejectWithValue(error.response?.data || serializeError(error));
    }
  }
);

export const updateSharedData = createAsyncThunk(
  'shared-data/updateSharedData',
  async ({ shared_dataId, shared_dataData }, { rejectWithValue }) => {
    try {
      const response = await agent.Notifications.partialUpdate(shared_dataId, shared_dataData);
      return response.data;
    } catch (error) {
      logger.logError(fileName, `updateSharedData error : ${error}`);
      return rejectWithValue(error.response?.data || serializeError(error));
    }
  }
);

const sharedDataSlice = createSlice({
  name: 'sharedData',
  initialState: {},
  reducers: {
    notificationPageUnloaded: () => ({}),
  },
  extraReducers: (builder) => {
      builder
        .addCase(getNotification.fulfilled, (state, action) => {
          logger.logDebug(fileName, `getNotifications.fulfilled action.payload.results: ${action.payload.results}`);
          state.notification = action.payload.results;        
          state.loading = false;
          state.errors = null;
        })
        .addCase(getNotification.rejected, (state, action) => {
          logger.logDebug(fileName, `getNotification.rejected action.error: ${action.error}`);
          state.notification = null;
          state.loading = false;
          state.error = action.error.message;
        })
        .addCase(findNotifications.fulfilled, (state, action) => {
          logger.logDebug(fileName, `findNotifications.fulfilled action.payload.results: ${action.payload.results}`);
          state.notifications = action.payload;          
          state.loading = false;
          state.error = null;
        })
        .addCase(findNotifications.rejected, (state, action) => {
          logger.logDebug(fileName, `findNotifications.rejected action.error: ${action.error}`);
          state.notifications = [];
          state.loading = false;
          state.error = action.error.message;
        });
    },
});

export const { notificationPageUnloaded } = sharedDataSlice.actions;

export default sharedDataSlice.reducer;
