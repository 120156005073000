import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findNotifications, updateNotification } from '../reducers/sharedData';
import { Alert } from 'reactstrap';
import Loading from './Loading';
import { useNavigate } from 'react-router-dom';

const NotificationsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { notifications, loading, error } = useSelector((state) => state.sharedData);

  const [dismissedNotifications, setDismissedNotifications] = useState(new Set());

  useEffect(() => {
    dispatch(findNotifications());
  }, [dispatch]);

  const handleDismiss = useCallback((notificationId) => {
    const notificationData = { read_at: new Date().toISOString(), delivered: true };
    dispatch(updateNotification({ notificationId, notificationData }));
    setDismissedNotifications((prev) => new Set(prev.add(notificationId)));
  }, [dispatch]);

  const handleNotificationClick = useCallback((notification) => {
    const notificationData = { read_at: new Date().toISOString(), delivered: true };
    dispatch(updateNotification({ notificationId: notification.id, notificationData }));    
    navigate('/SharedDataImport');
    
  }, [dispatch, navigate]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="notifications-list">
      {notifications && notifications.length > 0 ? (
        notifications
          .filter((notification) => !dismissedNotifications.has(notification.id))
          .map((notification) => (
            <Alert
              key={notification.id}
              color="warning"
              isOpen={true}
              fade={true}  // Enables fade animation            
              toggle={() => handleDismiss(notification.id)}
              onClick={(e) => {
                if (e.target.tagName !== 'BUTTON') {
                  handleNotificationClick(notification);
                }
              }}
            >
              <strong>{notification.message}</strong>
            </Alert>
          ))
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default NotificationsList;