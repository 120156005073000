import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import agent from '../api';
import logger from '../common/logger';

const fileName = '/src/reducers/profile.js';


export const getMyProfile = createAsyncThunk(
  'profile/getMyProfile',
  async (thunkApi) => {
    try {
      logger.logDebug(fileName, 'getMyProfile called');
      const response = await agent.Profiles.getMyProfile();
      logger.logDebug(fileName, 'getMyProfile success', { response });
      return response;
    } catch (error) {
      logger.logError(fileName, 'getMyProfile error', { error });
      throw error;
    }
  }
);


export const getProfile = createAsyncThunk(
  'profile/getProfile',
  async (arg, thunkApi) => {
    try {
      logger.logDebug(fileName, 'getProfile called', { arg });
      const response = await agent.Profiles.get(arg);
      logger.logDebug(fileName, 'getProfile success', { response });
      return response;
    } catch (error) {
      logger.logError(fileName, 'getProfile error', { error });
      throw error;
    }
  }
);

export const findProfile = createAsyncThunk(
  'profile/findProfile',
  async ({ search = '' } = {}, thunkApi) => {
    const searchQuery = search;

    try {
      logger.logDebug(fileName, 'Fetching Profiles', { searchQuery });

      const response = await agent.Profiles.all({ search });

      logger.logDebug(fileName, 'Fetched profiles successfully', { response });
      return response;
    } catch (error) {
      logger.logError(fileName, 'Error fetching profiles', { error });
      throw error;
    }
  }
);

export const follow = createAsyncThunk(
  'profile/follow',
  async (arg, thunkApi) => {
    try {
      logger.logDebug(fileName, 'follow called', { arg });
      const response = await agent.Profiles.follow(arg);
      logger.logDebug(fileName, 'follow success', { response });
      return response;
    } catch (error) {
      logger.logError(fileName, 'follow error', { error });
      throw error;
    }
  }
);

export const unfollow = createAsyncThunk(
  'profile/unfollow',
  async (arg, thunkApi) => {
    try {
      logger.logDebug(fileName, 'unfollow called', { arg });
      const response = await agent.Profiles.unfollow(arg);
      logger.logDebug(fileName, 'unfollow success', { response });
      return response;
    } catch (error) {
      logger.logError(fileName, 'unfollow error', { error });
      throw error;
    }
  }
);

const profileSlice = createSlice({
  name: 'profile',
  initialState: {},
  reducers: {
    profilePageUnloaded: (state, action) => {
      logger.logDebug(fileName, 'profilePageUnloaded called', { action });
      return {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfile.pending, (state, action) => {
        logger.logDebug(fileName, 'getProfile pending', { action });
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        logger.logDebug(fileName, 'getProfile fulfilled', { action });
        return { ...state, profile: action.payload };
      })
      .addCase(getProfile.rejected, (state, action) => {
        logger.logError(fileName, 'getProfile rejected', { action });
      })
      .addCase(getMyProfile.fulfilled, (state, action) => {
        logger.logDebug(fileName, 'getMyProfile fulfilled', { action });
        return { ...state, profile: action.payload };
      })
      .addCase(getMyProfile.rejected, (state, action) => {
        logger.logError(fileName, 'getMyProfile rejected', { action });
      })
      .addCase(findProfile.pending, (state, action) => {
        logger.logDebug(fileName, 'findProfile pending', { action });
      })
      .addCase(findProfile.fulfilled, (state, action) => {
        logger.logDebug(fileName, 'findProfile fulfilled', { action });
        return { ...state, profiles: action.payload };
      })
      .addCase(findProfile.rejected, (state, action) => {
        logger.logError(fileName, 'findProfile rejected', { action });
      })
      .addCase(follow.pending, (state, action) => {
        logger.logDebug(fileName, 'follow pending', { action });
      })
      .addCase(follow.fulfilled, (state, action) => {
        logger.logDebug(fileName, 'follow fulfilled', { action });
        return { ...state, profile: action.payload };
      })
      .addCase(follow.rejected, (state, action) => {
        logger.logError(fileName, 'follow rejected', { action });
      })
      .addCase(unfollow.pending, (state, action) => {
        logger.logDebug(fileName, 'unfollow pending', { action });
      })
      .addCase(unfollow.fulfilled, (state, action) => {
        logger.logDebug(fileName, 'unfollow fulfilled', { action });
        return { ...state, profile: action.payload };
      })
      .addCase(unfollow.rejected, (state, action) => {
        logger.logError(fileName, 'unfollow rejected', { action });
      });
  },
});

export const { profilePageUnloaded } = profileSlice.actions;

export default profileSlice.reducer;