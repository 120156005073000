// src/features/auth/authSlice.js
import {
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

import agent from '../../api';
import logger from '../../common/logger';

import {
  failureReducer,
  isApiError,
  loadingReducer,
  Status,
} from '../../common/utils';

/**
 * @typedef {object} User
 * @property {string} email
 * @property {string} username
 * @property {string} bio
 * @property {string} image
 *
 *
 * @typedef {object} AuthState
 * @property {Status} status
 * @property {string} token
 * @property {User}   user
 * @property {Record<string, string[]>} errors
 */

/**
 * Send a register request
 *
 * @param {object} argument
 * @param {string} argument.username
 * @param {string} argument.email
 * @param {string} argument.password
 */
export const register = createAsyncThunk(
  'auth/register',
  async ({ username, email, password }, thunkApi) => {
    logger.logInfo('src/features/auth/authSlice.js', 'Attempting to register user', { username, email });
    try {
      const {
        user: { token, ...user },
      } = await agent.Auth.register(username, email, password);

      logger.logInfo('src/features/auth/authSlice.js', 'User registered successfully', { user });
      return { token, user };
    } catch (error) {
      logger.logError('src/features/auth/authSlice.js', 'Error during registration', { error });
      if (isApiError(error)) {        
        const errorMessages = error?.user
          ? { errors: error.user }
          : { errors: { general: ['An unexpected error occurred. Please try again.'] } };
        return thunkApi.rejectWithValue(errorMessages);
      }

      throw error;
    }
  },
  {
    condition: (_, { getState }) => !selectIsLoading(getState()),
  }
);

/**
 * Send an Auth0 Callback request
 *
 * @param {object} argument
 * @param {string} argument.passAuth0Token
 */
export const auth0Tokenlogin = createAsyncThunk(
  'auth/auth0Tokenlogin',
  async ({ auth0Token }, thunkApi) => {
    logger.logInfo('src/features/auth/authSlice.js', 'Attempting Auth0 token login', { auth0Token });
    try {
      const {
        user: { token, ...user },
      } = await agent.Auth.auth0Tokenlogin(auth0Token);

      logger.logInfo('src/features/auth/authSlice.js', 'Auth0 token login successful', { user });
      return { token, user };
    } catch (error) {
      logger.logError('src/features/auth/authSlice.js', 'API Error on auth0Tokenlogin', { error });
      if (isApiError(error)) {
        const errorMessages = error?.user?.error
          ? { errors: { error: [error.user.error] } }
          : { errors: { general: ['An unexpected error occurred. Please try again.'] } };
        return thunkApi.rejectWithValue(errorMessages);
      }
      throw error;
    }
  }
);

/**
 * Send a Firebase token login request
 *
 * @param {object} argument
 * @param {string} argument.fireToken
 */
export const firebaseTokenlogin = createAsyncThunk(
  'auth/firebaseTokenlogin',
  async ({ fireToken }, thunkApi) => {
    logger.logInfo('src/features/auth/authSlice.js', 'Attempting Firebase token login', { fireToken });
    try {
      const {
        user: { token, ...user },
      } = await agent.Auth.firebaseTokenlogin(fireToken);

      logger.logInfo('src/features/auth/authSlice.js', 'Firebase token login successful', { user });
      return { token, user };
    } catch (error) {
      logger.logError('src/features/auth/authSlice.js', 'API Error on firebaseTokenlogin', { error });
      if (isApiError(error)) {
        const errorMessages = error?.user?.non_field_errors
          ? { errors: { non_field_errors: error.user.non_field_errors } }
          : { errors: { general: ['An unexpected error occurred. Please try again.'] } };
        return thunkApi.rejectWithValue(errorMessages);
      }
      throw error;
    }
  },
  {
    condition: (_, { getState }) => !selectIsLoading(getState()),
  }
);

/**
 * Send a login request
 *
 * @param {object} argument
 * @param {string} argument.email
 * @param {string} argument.password
 */
export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }, thunkApi) => {
    logger.logInfo('src/features/auth/authSlice.js', 'Attempting login', { email });
    try {
      const {
        user: { token, ...user },
      } = await agent.Auth.login(email, password);

      logger.logInfo('src/features/auth/authSlice.js', 'Login successful', { user });
      return { token, user };
    } catch (error) {
      logger.logError('src/features/auth/authSlice.js', 'API Error on login', { error });
      if (isApiError(error)) {
        const errorMessages = error?.user?.non_field_errors
          ? { errors: { non_field_errors: error.user.non_field_errors } }
          : { errors: { general: ['An unexpected error occurred. Please try again.'] } };
        return thunkApi.rejectWithValue(errorMessages);    
      }
      throw error;
    }
  },
  {
    condition: (_, { getState }) => !selectIsLoading(getState()),
  }
);

/**
 * Send a get current user request
 */
export const getUser = createAsyncThunk(
  'auth/getUser',
  async () => {
    logger.logInfo('src/features/auth/authSlice.js', 'Attempting to get current user');
    const {
      user: { token, ...user },
    } = await agent.Auth.current();

    logger.logInfo('src/features/auth/authSlice.js', 'Get current user successful', { user });
    return { token, user };
  },
  {
    condition: (_, { getState }) => Boolean(selectAuthSlice(getState()).token),
  }
);

/**
 * Send an update user request
 *
 * @param {object} argument
 * @param {string} argument.email
 * @param {string} argument.username
 * @param {string} argument.bio
 * @param {string} argument.image
 * @param {string} argument.password
 */
export const updateUser = createAsyncThunk(
  'auth/updateUser',
  async ({ email, username, bio, image, password }, thunkApi) => {
    logger.logInfo('src/features/auth/authSlice.js', 'Attempting to update user', { email, username });
    try {
      const {
        user: { token, ...user },
      } = await agent.Auth.save({ email, username, bio, image, password });

      logger.logInfo('src/features/auth/authSlice.js', 'Update user successful', { user });
      return { token, user };
    } catch (error) {
      logger.logError('src/features/auth/authSlice.js', 'API Error on update user', { error });
      if (isApiError(error)) {
        return thunkApi.rejectWithValue(error);
      }

      throw error;
    }
  },
  {
    condition: (_, { getState }) =>
      selectIsAuthenticated(getState()) && !selectIsLoading(getState()),
  }
);

/**
 * @type {AuthState}
 */
const initialState = {
  status: Status.IDLE,
  token: null,
  user: null,
  errors: {},  // Initialize errors as an empty object
};

/**
 * @param {import('@reduxjs/toolkit').Draft<AuthState>} state
 * @param {import('@reduxjs/toolkit').PayloadAction<{token: string, user: User}>} action
 */
function successReducer(state, action) {
  state.status = Status.SUCCESS;
  state.token = action.payload.token;
  state.user = action.payload.user;
  delete state.errors;
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    /**
     * Log out the user
     */
    logout: () => {
      logger.logInfo('src/features/auth/authSlice.js', 'Logging out user');
      return initialState;
    },
    /**
     * Update token
     *
     * @param {import('@reduxjs/toolkit').Draft<AuthState>} state
     * @param {import('@reduxjs/toolkit').PayloadAction<string>} action
     */
    setToken(state, action) {
      logger.logInfo('src/features/auth/authSlice.js', 'Setting token', { token: action.payload });
      state.token = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.fulfilled, successReducer)
      .addCase(register.fulfilled, successReducer)
      .addCase(auth0Tokenlogin.fulfilled, successReducer)
      .addCase(getUser.fulfilled, successReducer)
      .addCase(updateUser.fulfilled, successReducer);

    builder
      .addCase(login.rejected, failureReducer)
      .addCase(auth0Tokenlogin.rejected, failureReducer)
      .addCase(register.rejected, failureReducer)
      .addCase(updateUser.rejected, failureReducer);

    builder.addMatcher(
      (action) => action.type.endsWith('/pending'),
      loadingReducer
    );
  },
});

export const selectAuthSlice = (state) => state.auth;

export const selectIsLoading = createSelector(
  selectAuthSlice,
  (state) => state.status === Status.LOADING
);

export const selectIsAuthenticated = createSelector(
  selectAuthSlice,
  (state) => Boolean(state.token)
);

export const selectUser = createSelector(selectAuthSlice, (state) => state.user);

export const selectUsername = createSelector(
  selectUser,
  (user) => user?.username ?? null
);

export const selectErrors = createSelector(
  selectAuthSlice,
  (state) => state.errors
);

export const { logout, setToken } = authSlice.actions;

export default authSlice.reducer;