import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import agent from '../api';
import logger from '../common/logger';

const loggingMetaName = 'Reducer: src/reducers/trainingOutcomeList.js';

const initialState = {
  trainingMatchId: 0,
  trainingMatchOutcomes: [],  
  trainingMatchOutcomesCount: 0,
  loading: false,
  error: null,
};

export const getAllTrainingMatchOutcomes = createAsyncThunk(
  'trainingMatchOutcomeList/getAllTrainingMatchOutcomes',
  async (matchId, thunkAPI) => {
    try {
      logger.logDebug(`${loggingMetaName}`, `getAllTrainingMatchOutcomes called with matchId: ${matchId}`);
      const response = await agent.TrainingMatches.Outcomes.all(matchId);
      return { matchId, ...response };
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


const trainingMatchOutcomeListSlice = createSlice({
  name: 'trainingMatchOutcomeList',
  initialState,
  reducers: {
    homePageUnloaded: () => initialState,
    changeTab: (state, action) => {
      state.tab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTrainingMatchOutcomes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTrainingMatchOutcomes.fulfilled, (state, action) => {
        const { matchId, results, count } = action.payload;
        logger.logDebug(`${loggingMetaName}`, `getAllTrainingMatchOutcomes.fulfilled action.payload.results: ${results}`);
        state.trainingMatchId = matchId;
        state.trainingMatchOutcomes = results;
        state.trainingMatchOutcomesCount = count;        
        state.loading = false;
        state.error = null;
      })
      .addCase(getAllTrainingMatchOutcomes.rejected, (state, action) => {
        logger.logDebug(`${loggingMetaName}`, `getAllTrainingMatchOutcomes.rejected action.error: ${action.error}`);
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { homePageUnloaded } = trainingMatchOutcomeListSlice.actions;

export default trainingMatchOutcomeListSlice.reducer;