import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import agent from '../api';
import {
  getUser,
  login,
  logout,
  register,
  setToken,
  updateUser,
} from '../features/auth/authSlice';

const initialState = {
  appName: 'Jitz Journey',
  appLoaded: false,
  viewChangeCounter: 0,
  redirectTo: undefined,
};


export const appLoad = (token) => (dispatch) => {
  dispatch(commonSlice.actions.loadApp());

  if (token) {
    agent.setToken(token);
    dispatch(setToken(token));
    return dispatch(getUser());
  }
};


const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    loadApp(state) {
      state.appLoaded = true;
    },
    clearRedirect(state) {
      delete state.redirectTo;
    },
    
    setRedirectTo(state, action) {
      state.redirectTo = action.payload; // Set the redirect path
    },
  },
  extraReducers: (builder) => {

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.redirectTo = '/';
    });

    builder.addCase(login.fulfilled, (state, action) => {
      state.redirectTo = '/';
    });

    builder.addCase(register.fulfilled, (state, action) => {
      state.redirectTo = '/';
    });

    builder.addCase(logout, (state) => {
      state.redirectTo = '/';
    });

    // builder.addMatcher(
    //   (action) =>
    //     [          
    //       homePageUnloaded.type,
    //       profilePageUnloaded.type,
    //     ].includes(action.type),
    //   (state) => {
    //     state.viewChangeCounter++;
    //   }
    // );
  },
});

export const { clearRedirect, setRedirectTo  } = commonSlice.actions;

export default commonSlice.reducer;
