// src/components/Footer.jsx
import React from "react";
import { Container, Row, Col } from "reactstrap";



const Footer = () => {
  const backend = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000/api/v0";
  const debugLevel = process.env.REACT_APP_LOG_LEVEL || 'DEBUG';
  const commitRef = process.env.REACT_APP_VERCEL_GIT_COMMIT_REF || "local";
  const commitSha = process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA ? process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA.substring(0, 7) : 'Build';
  const buildVersion = `${commitRef}-${commitSha}`;

  return (
    <footer className="footer mt-auto py-3">
      <Container>
        <Row>
          <Col className="text-center">
            <p className="mb-0">Backend #: {backend}</p>
            <p className="mb-0">Build #: {buildVersion}</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;