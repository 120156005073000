
/**
 * @typedef {Object} SharedData
  * @property {Integer} id
 */

const SharedData = (requests) => ({
    /**
     * Get all SharedDatas
     *
     * @param {Object} query SharedData's query parameters
     * @returns {Promise<SharedDatasResponse>}
     */
    all: (query) => requests.get(`/shared-data`, query),

    /**
     * Get one SharedData by ID
     *
     * @param {Number} id SharedData's ID
     * @returns {Promise<SharedDataResponse>}
     */
    get: (id) => requests.get(`/shared-data/${id}`),

    /**
     * Create a new SharedData
     *
     * @param {Object} shared_data
      * @param {Integer} shared_data.id
     * @param {Integer} shared_data.parent_belt
     * @param {String} shared_data.name
     * @returns {Promise<SharedDataResponse>}
     */
    create: (shared_data) => requests.post(`/shared-data`, { shared_data }),

    /**
     * Update one SharedData
     *
     * @param {Number} id SharedData's ID
     * @param {Partial<SharedData>} shared_data
     * @returns {Promise<SharedDataResponse>}
     */
    update: (id, shared_data) => requests.put(`/shared-data/${id}`, { shared_data }),

    /**
     * Partially update one SharedData
     *
     * @param {Number} id SharedData's ID
     * @param {Partial<SharedData>} shared_data
     * @returns {Promise<SharedDataResponse>}
     */
    partialUpdate: (id, shared_data) => requests.patch(`/shared-data/${id}`, { shared_data }),

    /**
     * Remove one SharedData
     *
     * @param {Number} id SharedData's ID
     * @returns {Promise<{}>}
     */
    del: (id) => requests.del(`/shared-data/${id}`),
});

export default SharedData;
