// /src/features/auth/Auth0ProviderWithHistory.jsx
import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import logger from '../../common/logger';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;  

  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    logger.logDebug('Auth0ProviderWithHistory', "onRedirectCallback called with appState:" +  appState);
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
    domain={domain}
    clientId={clientId}    
    authorizationParams={{
      redirect_uri: window.location.origin + '/auth0login',
        scope: 'openid profile email',            
        response_type: 'id_token token'    
    }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;