// src/api/index.js
import createAuth from './auth';
import createArticles from './articles';
import createBelts from './belts';
import createEmails from './emails';
import createGyms from './gyms';
import createJourney from './journey';
import createNotificatios from './notifications';
import createProfiles from './profiles';
import createReports from './reports';
import createSharedData from './shared-data';
import createTechniques from './techniques';
import createTrainingMatches from './training-matches';
import createTrainingOutcomes from './training-outcomes';
import createTrainingSessions from './training-sessions';
import logger from '../common/logger';

// import Tags from './tags';

const API_ROOT =
  process.env.REACT_APP_BACKEND_URL ?? 'http://localhost:8000/api/v0';

/**
 * Serialize object to URL params
 *
 * @param {Record<string, unknown>} object
 * @returns {String}
 */
function serialize(object) {
  const params = [];

  for (const param in object) {
    if (Object.hasOwnProperty.call(object, param) && object[param] != null) {
      params.push(`${param}=${encodeURIComponent(object[param])}`);
    }
  }

  return params.join('&');
}

let token = null;

/**
 *
 * @typedef {Object} ApiError
 * @property {{[property: string]: string}} errors
 */

/**
 * API client
 *
 * @param {String} url The endpoint
 * @param {Object} body The request's body
 * @param {('GET'|'DELETE'|'PUT'|'POST')} [method='GET'] The request's method
 *
 * @throws {@link ApiError API Error}
 *
 * @returns {Promise<Object>} API response's body
 */
const agent = async (url, body, method = 'GET') => {
  const headers = new Headers();

  if (body) {
    headers.set('Content-Type', 'application/json');
  }

  // Log request before sending and before adding the Token Header
  logger.logInfo('src/api/index.js', 'API Request Details:', {
    url: `${API_ROOT}${url}`,
    method,
    headers: JSON.stringify(headers, null, 2),
    body: body ? JSON.stringify(body, null, 2) : 'None',
  });

  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  try {
    // Send the request
    const response = await fetch(`${API_ROOT}${url}`, {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
    });

    // Read the response body once and store it for further processing
    const responseText = await response.text();

    logger.logDebug('src/api/index.js', `API Response Details: 
    URL: ${response.url} 
    Status: ${response.status} 
    Headers: ${JSON.stringify(response.headers, null, 2)}`);

    let result;
    try {
      // Parse the response body
      result = JSON.parse(responseText); // Use JSON.parse() on the stored text
      // Log the parsed response body
      logger.logInfo('src/api/index.js', `API ${response.status} Response Received from ${response.url} ` , { result });
    } catch (error) {
      // If error parsing JSON, log the raw response text
      logger.logError('src/api/index.js', `API Error Parsing Response from ${response.url} JSON:`, {
        status: response.status,
        errorText: responseText,
      });

      result = { errors: { [response.status]: [responseText] } };
    }

    // If the response is not okay, throw the result
    if (!response.ok) {
      logger.logError('src/api/index.js', 'API Error:', {
        status: response.status,
        result,
      });
      throw result;
    }

    // Return the parsed response body
    return result;
  } catch (error) {
    logger.logError('src/api/index.js', 'API Request Failed:', { error });
    throw error;
  }
};

const requests = {
  /**
   * Send a DELETE request
   *
   * @param {String} url The endpoint
   * @returns {Promise<Object>}
   */
  del: (url) => agent(url, undefined, 'DELETE'),
  /**
   * Send a GET request
   *
   * @param {String} url The endpoint
   * @param {Object} [query={}] URL parameters
   * @param {Number} [query.limit=10]
   * @param {Number} [query.page]   
   * @returns {Promise<Object>}
   */
  get: (url, query = {}) => {
    logger.logDebug('src/api/index.js', `Requests Preparing Get with url: ${url} and query: ${JSON.stringify(query)}`);
  
    // If query has a nested "query" object, flatten it
    const flatQuery = { ...query.query, ...query }; // Flattening the query
  
    // Calculate offset if page is specified
    if (Number.isSafeInteger(flatQuery?.page)) {
      flatQuery.limit = flatQuery.limit || 10; // Default to 10 if limit is not provided      
    }
    
    delete flatQuery.query; // Remove the query parameter which is the just the container parameter
  
    const isEmptyQuery = flatQuery == null || Object.keys(flatQuery).length === 0;
  
    // Send the request with the flattened query parameters
    return agent(isEmptyQuery ? url : `${url}?${serialize(flatQuery)}`);
  },
  /**
   * Send a PUT request
   *
   * @param {String} url The endpoint
   * @param {Record<string, unknown>} body The request's body
   * @returns {Promise<Object>}
   */
  put: (url, body) => agent(url, body, 'PUT'),

   /**
   * Send a PATCH request
   *
   * @param {String} url The endpoint
   * @param {Record<string, unknown>} body The request's body
   * @returns {Promise<Object>}
   */
  patch: (url, body) => agent(url, body, 'PATCH'),

  /**
   * Send a POST request
   *
   * @param {String} url The endpoint
   * @param {Record<string, unknown>} body The request's body
   * @returns {Promise<Object>}
   */
  post: (url, body) => agent(url, body, 'POST'),
};

const Articles = createArticles(requests);
const Auth = createAuth(requests);
const Belts = createBelts(requests);
const Emails = createEmails(requests);
const Gyms = createGyms(requests);
const Journey = createJourney(requests);
const Notifications = createNotificatios(requests);
const Profiles = createProfiles(requests);
const Reports = createReports(requests);
const SharedData = createSharedData(requests);
const Techniques = createTechniques(requests);
const TrainingMatches = createTrainingMatches(requests);
const TrainingOutcomes = createTrainingOutcomes(requests);
const TrainingSessions = createTrainingSessions(requests);



export default {
  Articles,
  Auth,
  Belts,
  Emails,
  Gyms,
  Journey,
  Notifications,
  Profiles,
  Reports,
  SharedData,
  Techniques,
  TrainingMatches,
  TrainingOutcomes,
  TrainingSessions,  
  setToken: (_token) => {
    token = _token;
  },
};